.btn-chocalate {
  background: #200d0f;
  color: #fff;
}

.mint-box{
border: none!important;
    box-shadow: 0 0 15px rgb(240 233 233 / 79%);
    background: transparent!important;
}

/* accordion css */
.accordion__panel {
  text-align: left;
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

.bg-light-grey h2 {
  color: #fff;
}
.accordion__button {
  background-color: #000!important;
    /* background: linear-gradient(
1deg, rgb(0 168 255) 0%, rgb(0 0 0) 100%); */
    color: #fff!important;
    cursor: pointer;
    border: 1px solid #00a8ff!important;
    padding: 18px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    border-radius: 10px;
    border: none;
    margin: 10px 0px;
}
.accordion__button:hover {
  background-color: #000;
 
}
.accordion__button:before {
  display: inline-block;
  content: '';
  float: right;
  height: 10px;
  position: relative;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  top: 8px;
}
/* ============================= */
.bg-light-grey {
  background: linear-gradient(
174deg, #181818, #080808);
  box-shadow: 0px 5px 10px #000;
  min-height: 400px;
  border-radius: 20px;
  padding: 20px 20px 0px 0px;
  border: 1px solid #00a8ff;
}
.banner-btns.d-flex.flex-wrap span {
  font-size: 16px;
  font-weight: 700;
}